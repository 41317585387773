import logo from "./images/logo/BannerLogo.jpg";
import "./App.css";
import Footer from "./components/layout/Footer";
import YoutubeEmbed from "./components/media/YoutubeEmbed";

function App() {
    return (
        <div className="App">
            {/* <TestNav />
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
            </header> */}
            <div className="container">
                <div className="row">
                    <div className="col-4">
                        <img src={logo} className="img-fluid" alt="logo" />
                    </div>
                    <div className="col-8"></div>
                </div>
                <div className="row pt-4">
                    <div className="col-12">
                        <div className="display-6 pb-2">
                            Flesh and Bones{" "}
                            <small class="text-muted">From Zombies 2</small>
                        </div>
                    </div>
                    <YoutubeEmbed embedId="BW_MPgYlZSI" />
                </div>
                <div className="row pt-4">
                    <div className="col-12">
                        <div className="display-6 pb-2">
                            Call to the Wild{" "}
                            <small class="text-muted">From Zombies 2</small>
                        </div>
                    </div>
                    <YoutubeEmbed embedId="6JhX4m1eXMA" />
                </div>
                <div className="row pt-4">
                    <div className="col-12">
                        <div className="display-6 pb-2">
                            Like the zombies do{" "}
                            <small class="text-muted">From Zombies 2</small>
                        </div>
                    </div>
                    <YoutubeEmbed embedId="BspXIPcDOss" />
                </div>
                <div className="row pt-4">
                    <div className="col-12">
                        <div className="display-6 pb-2">
                            Rolling in the deep{" "}
                            <small class="text-muted">With Mummy</small>
                        </div>
                    </div>
                    <YoutubeEmbed embedId="iyPMrrPZK1w" />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default App;
