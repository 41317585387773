import React from "react";

export default function Footer() {
    return (
        <>
            <div className="row">
                <span className="align-bottom">
                    <footer>
                        <small>
                            © 2022 RERLH development. All rights reserved.
                        </small>
                    </footer>
                </span>
            </div>
        </>
    );
}
